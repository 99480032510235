export const regionCookieName = 'preferred-region'

/**
 * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 for country code
 * @see https://mcoproduct.atlassian.net/browse/WRP1-445 for country/region requirement
 * NOTE region and country are in a one-to-many relationship
 */
export const eeaCountryList = [
  'GB',
  'GBR',
  'CH',
  'NO',
  'LI',
  'IS',
  'SE',
  'ES',
  'SI',
  'SK',
  'RO',
  'PT',
  'PL',
  'NL',
  'MT',
  'LU',
  'LT',
  'LV',
  'IT',
  'IE',
  'HU',
  'GR',
  'DE',
  //  'FR',
  'FI',
  'EE',
  'DK',
  'CZ',
  'CY',
  'HR',
  'BG',
  'BE',
  'AT',
]

//DEBUG
export type Regions = 'US' | 'EEA' | 'SG' | 'BR' | 'AU' | 'GLOBAL' | 'TR' | 'FR'
export const regions: Regions[] = [
  'US',
  'EEA',
  'SG',
  'BR',
  'AU',
  'TR',
  'FR',
  'GLOBAL',
]

export const US = 'US'
export const EEA = 'EEA'
export const SG = 'SG'
export const BR = 'BR'
export const AU = 'AU'
export const TR = 'TR'
export const FR = 'FR'
export const GLOBAL = 'GLOBAL'

/**
 * NOTE ❗️ region cookie is in upper letters, but region path is in lower case
 */

//NOTE lower case of regions, can be improved in future
export type LowerRegions =
  | 'us'
  | 'eea'
  | 'sg'
  | 'br'
  | 'au'
  | 'global'
  | 'tr'
  | 'fr'
export const lowerRegions: LowerRegions[] = [
  'us',
  'eea',
  'sg',
  'br',
  'au',
  'tr',
  'fr',
  'global',
]
export type AbbrRegions =
  | 'usa'
  | 'eea'
  | 'sgp'
  | 'bra'
  | 'aus'
  | 'ww'
  | 'tur'
  | 'fra'
export const abbrRegions: AbbrRegions[] = [
  'usa',
  'eea',
  'sgp',
  'bra',
  'aus',
  'tur',
  'fra',
  'ww',
]
