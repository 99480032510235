import './layout-style.scss'
import './layout.css'

import { useNavFooter, useUserRegionCH } from '@cdc-internal/mkt-nav-footer'
import { ThemeProvider as NextThemeProvider } from '@emotion/react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { useAtom } from 'jotai'
import React, { useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { ScreenClassProvider } from 'react-grid-system'
import Helmet from 'react-helmet'
import { I18nextProvider } from 'react-i18next'

import { OneLinkProvider } from '@/modules/components/hooks/useOneLink.tsx'
import { regionCookieName } from '@/modules/configs/region'
import { GeoCountryProvider } from '@/utils/useGeoCountry'

import { Atom } from '../../atom'
import { CategoriesProvider, PostsProvider } from '../../hooks'
import i18n from '../../i18n/config'
import nextTheme from '../../modules/next-theme'
import { ThemeGlobalStyle, ThemeProvider } from '../../modules/theme.jsx'
import SearchBar from '../search/search'

const AdaptLink = ({ href, style, children, ...restProps }) => (
  <GatsbyLink to={href} activeStyle={style} {...restProps}>
    {children}
  </GatsbyLink>
)

const Layout = ({ children, pageContext }) => {
  const isNewTemplateLayout = pageContext.isNewTemplate
  const isFSContainer = pageContext.isFSContainer

  const userRegion = useUserRegionCH()

  const [cookies, setCookie] = useCookies([regionCookieName, 'locale'])
  const [lang, setLang] = useAtom(Atom.lang)

  const onSearch = (keyword) => {
    navigate(`/search/?keyword=${keyword}`, { replace: false })
  }

  if (pageContext) {
    i18n.changeLanguage(pageContext.lang)
  }

  const langChangeCallback = useCallback(
    (lang) => {
      i18n.changeLanguage(lang)
      setCookie('locale', lang, {
        path: '/',
      })
      setLang(lang)
    },
    [i18n, setCookie]
  )

  const { Nav, Footer } = useNavFooter({
    lang: lang,
    country: userRegion,
    hasLangCountrySwitch: pageContext.hasLangCountrySwitch
      ? pageContext.hasLangCountrySwitch
      : false,
    langChangeCallback,
    onSearch: onSearch,
    hasSearchBar: true,
    Link: AdaptLink,
    mktBaseUrl: process.env.GATSBY_MKT_BASE_URL,
  })

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: pageContext.lang,
        }}
        bodyAttributes={{
          class: 'fs-unmask',
        }}
      >
        <script src="https://cdn.optimizely.com/js/22092170568.js"></script>
        <script src="/lib/gt.js"></script>
      </Helmet>
      <I18nextProvider i18n={i18n}>
        <PostsProvider>
          <CategoriesProvider>
            <NextThemeProvider theme={nextTheme}>
              <ThemeProvider>
                <ScreenClassProvider>
                  <OneLinkProvider>
                    <GeoCountryProvider>
                      <ThemeGlobalStyle />
                      <div
                        className={`${
                          i18n.language === 'ko'
                            ? 'ko-global-wrapper'
                            : 'global-wrapper'
                        }`}
                      >
                        <Nav />
                        <main
                          className={
                            isNewTemplateLayout
                              ? 'container-new-template'
                              : isFSContainer
                              ? 'container-financial-statement'
                              : 'container'
                          }
                        >
                          {children}
                        </main>
                        <div className="container-footer">
                          <Footer />
                        </div>
                        <SearchBar />
                      </div>
                    </GeoCountryProvider>
                  </OneLinkProvider>
                </ScreenClassProvider>
              </ThemeProvider>
            </NextThemeProvider>
          </CategoriesProvider>
        </PostsProvider>
      </I18nextProvider>
    </>
  )
}

export default Layout
