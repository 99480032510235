import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import getPageLangArticles from '@/utils/getPageLangArticles'

const PostsContext = createContext({})

const PostsProvider = ({ children }) => {
  const [posts, setPosts] = useState({})
  const data = useGetAllPosts()
  const { i18n } = useTranslation()

  useEffect(() => {
    const pageLangUniversities = getPageLangArticles(
      data.allWpUniversityPost.edges,
      i18n.language
    )
    const pageLangEvents = getPageLangArticles(
      data.allWpEvent.edges,
      i18n.language
    )
    const all = [
      ...pageLangEvents,
      ...pageLangUniversities,
      ...data.allWpProductSingleNews.edges,
      ...data.allWpReportsAndanalysis.edges,
      ...data.allWpCompanySingleNews.edges,
      ...data.allWpNftStory.edges,
    ]

    setPosts({
      all,
      events: pageLangEvents,
      university: pageLangUniversities,
      productNews: data.allWpProductSingleNews.edges,
      marketUpdates: data.allWpMarketUpdate.edges,
      research: data.allWpReportsAndanalysis.edges,
      nftStories: data.allWpNftStory.edges,
    })
  }, [i18n.language, data])

  const state = posts

  return <PostsContext.Provider value={state}>{children}</PostsContext.Provider>
}

const usePosts = () => {
  const context = useContext(PostsContext)

  if (!context) {
    throw new Error('useLocale must be used within an PostsProvider')
  }

  return context
}

export { PostsProvider, usePosts }

const useGetAllPosts = () => {
  const posts = useStaticQuery(graphql`
    {
      allWpEvent {
        edges {
          node {
            id
            title
            slug
            excerpt
            nodeType
            uri
            date(formatString: "MMM DD, YYYY")
            dateGmt
            categories {
              nodes {
                name
                slug
                description
                id
              }
            }
            tags {
              nodes {
                name
              }
            }
            acf: acfEvents {
              eventDatetime {
                endDate
                endTime
                fieldGroupName
                startDate
                startTime
              }
              eventLocation
              readingDuration
            }
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
            translated {
              id
              locale {
                locale
              }
            }
            locale {
              locale
              id
            }
          }
        }
      }
      allWpUniversityPost {
        edges {
          node {
            id
            title
            slug
            excerpt
            nodeType
            uri
            date(formatString: "MMM DD, YYYY")
            dateGmt
            categories {
              nodes {
                name
                slug
                description
                id
              }
            }
            tags {
              nodes {
                name
                slug
              }
            }
            translated {
              id
              locale {
                locale
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
            locale {
              locale
            }
          }
        }
      }
      allWpProductSingleNews {
        edges {
          node {
            id
            title
            slug
            excerpt
            nodeType
            uri
            date(formatString: "MMM DD, YYYY")
            dateGmt
            categories {
              nodes {
                name
                slug
                description
                id
              }
            }
            tags {
              nodes {
                name
                slug
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
            locale {
              locale
            }
          }
        }
      }
      allWpReportsAndanalysis {
        edges {
          node {
            id
            title
            slug
            excerpt
            nodeType
            uri
            date(formatString: "MMM DD, YYYY")
            dateGmt
            categories {
              nodes {
                name
                slug
                description
                id
              }
            }
            tags {
              nodes {
                name
                slug
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
            locale {
              locale
              id
            }
          }
        }
      }
      allWpCompanySingleNews {
        edges {
          node {
            id
            title
            slug
            excerpt
            nodeType
            uri
            date(formatString: "MMM DD, YYYY")
            dateGmt
            categories {
              nodes {
                name
                slug
                description
                id
              }
            }
            tags {
              nodes {
                name
                slug
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
            locale {
              locale
              id
            }
          }
        }
      }
      allWpMarketUpdate {
        edges {
          node {
            id
            title
            slug
            excerpt
            nodeType
            uri
            date(formatString: "MMM DD, YYYY")
            dateGmt
            categories {
              nodes {
                id
                slug
              }
            }
            tags {
              nodes {
                name
                slug
              }
            }
            acf: acfMarketUpdates {
              keywords
              readingDuration
              dateRange {
                dateFrom
                dateTo
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
          }
        }
      }
      allWpNftStory {
        edges {
          node {
            id
            title
            slug
            nodeType
            excerpt
            uri
            date(formatString: "MMM DD, YYYY")
            dateGmt
            categories {
              nodes {
                name
                slug
                description
                id
              }
            }
            acf: acfNftStories {
              readingDuration
            }
            featuredImage {
              node {
                id
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  return posts
}
