// custom CSS styles
import './src/scss/style.scss'
import 'react-tabs/style/react-tabs.css'
import wrapWithProvider from './src/connect/provider'

export const onPreRouteUpdate = () => {
  document.body.scrollTop = 0
}

export const wrapRootElement = wrapWithProvider
