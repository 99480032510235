import React from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'

import rootReducers from '../redux/reducers'

const wrapRootElement = ({ element, pageContext }) => {
  const store = createStore(rootReducers)

  return (
    <>
      <Provider store={store}>{element}</Provider>
    </>
  )
}

export default wrapRootElement
