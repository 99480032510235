import axios from 'axios'
import { useEffect, useState } from 'react'

import getLocationByCountry from '../helpers/get-location-by-country'

function useLocation() {
  // creating IP state
  const [locationIP, setLocationIP] = useState('')

  // creating function to load ip address from the API
  const getData = async () => {
    await axios.get('https://geolocation-db.com/json/').then((res) => {
      const codeCountry = getLocationByCountry(res.data.country_code)
      setLocationIP(codeCountry)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return locationIP
}

export default useLocation
