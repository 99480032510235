import './search-style.scss'

import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CloseIcon } from '@/assets/icons/crypto_general_icons_close.svg'
import { CLOSE_SEARCH } from '@/redux/actions/types'

const SearchBar = () => {
  const isOpenSearch = useSelector((state) => state.search.isOpenSearch)
  const dispatch = useDispatch()
  const handleCloseSearch = () => {
    dispatch({ type: CLOSE_SEARCH })
  }
  const { i18n } = useTranslation()
  const Input = ({ field, form, ...props }) => (
    <div>
      <input
        className="search-bar-form-input"
        {...field}
        {...props}
        placeholder="Search"
      />
      <CloseIcon
        className="search-bar-close-icon"
        onClick={handleCloseSearch}
      />
    </div>
  )

  return (
    <div
      className={classNames({
        'search-bar-container': true,
        'is-open': isOpenSearch,
      })}
    >
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        bodyAttributes={{
          class: isOpenSearch ? 'is-search-open fs-unmask' : 'fs-unmask',
        }}
      />
      <div className="search-bar-box">
        <div className="search-bar-input-box">
          <Formik
            initialValues={{ query: '' }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              handleCloseSearch()
              // const url = i18n.language.startsWith('en')
              //   ? `/search?keyword=${values.query}`
              //   : `/search/${i18n.language}?keyword=${values.query}/`
              const url = `/search?keyword=${values.query}`
              navigate(url, { replace: false })
            }}
          >
            <Form>
              <Field name="query" component={Input} />
            </Form>
          </Formik>
        </div>
        <div className="search-bar-keywords-container">
          <p className="search-bar-keywords">Common Keywords:&nbsp;</p>
          <p className="search-bar-keywords">
            Ethereum / Dogecoin / Dapp / Tokens
          </p>
        </div>
      </div>
    </div>
  )
}

export default SearchBar
