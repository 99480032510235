import { combineReducers } from 'redux'

import LocaleReducer from './locale-reducer'
import PostsReducer from './posts-reducer'
import SearchReducer from './search-reducer'

const rootReducer = combineReducers({
  locale: LocaleReducer,
  filter: PostsReducer,
  search: SearchReducer,
})

export default rootReducer
