import React, { createContext, useContext, useEffect, useState } from 'react'

export const DEFAULT_GEO_COUNTRY = 'GLOBAL'

export type GeoCountryContextProps = {
  geoCountry: string
}

export const GeoCountryContext = createContext<GeoCountryContextProps>({
  geoCountry: DEFAULT_GEO_COUNTRY,
})

export const GeoCountryProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [geoCountry, setGeoCountry] = useState<string>(DEFAULT_GEO_COUNTRY)
  useEffect(() => {
    if (localStorage && localStorage.getItem('@NAV/CUSTOM_COUNTRY')) {
      setGeoCountry(
        localStorage.getItem('@NAV/CUSTOM_COUNTRY') ?? DEFAULT_GEO_COUNTRY
      )
      return
    }

    const fetchGeoLocation = async () => {
      const result = await fetch('https://crypto.com/cdn-cgi/trace').then(
        (response) => response.text()
      )
      const matches = result.match(/loc=([A-Z]+)/)

      if (matches && matches[1]) {
        return matches[1]
      }
      return DEFAULT_GEO_COUNTRY
    }
    fetchGeoLocation().then((country) => setGeoCountry(country))
  }, [])

  return (
    <GeoCountryContext.Provider {...props} value={{ geoCountry }}>
      {props.children}
    </GeoCountryContext.Provider>
  )
}

export const useGeoCountry = (): GeoCountryContextProps => {
  return useContext(GeoCountryContext)
}
