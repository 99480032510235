import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'

const BaseLang = 'en'
export default function getPageLangArticles(articles, pageLang) {
  const allArticles = cloneDeep(articles)

  const allArticlesById = allArticles.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.node.id]: curr,
    }
  }, {})

  const allArticlesInEn = allArticles.filter((article) => {
    return article.node.locale?.locale === BaseLang
  })

  const allArticlesLangEnMap = {}
  allArticles.forEach((article) => {
    if (!article.node.locale?.locale) return
    const { id, locale, translated } = article.node
    if (translated && Array.isArray(translated)) {
      const allLangs = [{ id, locale }, ...translated]
      const enLang = find(allLangs, (lang) => lang.locale?.locale === BaseLang)
      if (enLang) {
        const allLangsObj = allLangs.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.locale.locale]: curr,
          }
        }, allArticlesLangEnMap[enLang.id] ?? {})
        allArticlesLangEnMap[enLang.id] = allLangsObj
      }
    }
  })

  const allArticlesInPageLang =
    pageLang === BaseLang
      ? allArticlesInEn
      : allArticlesInEn.map((article) => {
          if (allArticlesLangEnMap[article.node.id]?.[pageLang]) {
            const articleInPageLang =
              allArticlesById[
                allArticlesLangEnMap[article.node.id][pageLang].id
              ]
            if (articleInPageLang) {
              const cArticle = { node: { ...article.node } }
              cArticle.node.content =
                articleInPageLang.node.content ?? cArticle.node.content
              cArticle.node.title =
                articleInPageLang.node.title ?? cArticle.node.title
              cArticle.node.excerpt =
                articleInPageLang.node.excerpt ?? cArticle.node.excerpt
              cArticle.node.featuredImage =
                articleInPageLang.node.featuredImage ??
                cArticle.node.featuredImage
              return cArticle
            } else return article
          } else {
            return article
          }
        })
  return allArticlesInPageLang
}
