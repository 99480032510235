import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const getTransWithoutMissingKey = (key, i18n, t) => {
  return i18n.exists(key) ? t(key) : ''
}

export const languages = [
  'en',
  'es',
  'fr',
  'it',
  'pt',
  'tr',
  'id',
  'pt-BR',
  'ko',
]

export const langs = languages.map((lang) => lang.toLowerCase())

const resources = languages.reduce((prev, language) => {
  return {
    ...prev,
    [language]: {
      translations: require(`./locales/${language}/translation.json`),
    },
  }
}, {})
if (!i18n.isInitialized)
  i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    resources,
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
  })

i18n.languages = languages

export default i18n

export const eventsLanguages = [
  { path: '', code: 'en' },
  { path: '/fr', code: 'fr' },
  { path: '/it', code: 'it' },
  { path: '/tr', code: 'tr' },
  { path: '/pt-br', code: 'pt-BR' },
]
