import { CHANGE_LANGUAGE } from '../actions/types'

const defaultState = {
  language: 'es',
  languages: [
    { label: 'EN', value: 'en', code: 'en' },
    { label: '中文', value: 'zh-hans', code: 'zh-hans' },
    { label: '한국어', value: 'ko', code: 'ko' },
  ],
}

const LocaleReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      }
    }
    default:
      return state
  }
}

export default LocaleReducer
