import qs from 'qs'
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react'

interface OneLinkContextType {
  queryString: string
}

const OneLinkContext = createContext(null)

export const OneLinkProvider: FC = (props) => {
  const [queryString, setQueryString] = useState('')
  useEffect(() => {
    setQueryString(generatequeryString())
  }, [])

  function generatequeryString() {
    const queryStringObject = qs.parse(window.location.search.replace('?', ''))
    const queryMapping = {
      utm_source: 'pid',
      utm_campaign: 'c',
      gclid: 'af_sub2',
    }

    const newQuery = {}
    for (const [key, value] of Object.entries(queryStringObject)) {
      if (!queryMapping[key]) continue
      newQuery[queryMapping[key]] = value
    }

    if (newQuery == null || Object.keys(newQuery).length === 0) {
      return ''
    }

    newQuery['af_js_web'] = true
    return '?' + qs.stringify(newQuery)
  }

  return <OneLinkContext.Provider {...props} value={{ queryString }} />
}

export const useOneLink = (): OneLinkContextType => {
  return useContext(OneLinkContext)
}
