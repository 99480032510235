import { graphql, useStaticQuery } from 'gatsby'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getCategoryAndSubcategories } from '../helpers/get-subcategory'

const useGetAllCategories = () => {
  const categories = useStaticQuery(graphql`
    query {
      allWpCategory {
        edges {
          node {
            name
            nodeType
            id
            slug
            description
            wpChildren {
              nodes {
                id
                name
                slug
                description
              }
            }
          }
        }
        totalCount
      }
    }
  `)
  return categories.allWpCategory
}

const CategoriesContext = createContext({
  withSubCategories: [],
})

const CategoriesProvider = ({ children }) => {
  const [catgs, setCatgs] = useState([])
  const [withSubCategories, setWithSubCategories] = useState([])
  const { edges } = useGetAllCategories()
  const { i18n } = useTranslation()

  useEffect(() => {
    setCatgs(edges)
  }, [edges])
  useEffect(() => {
    const { categories } = getCategoryAndSubcategories(catgs, i18n.language)
    setWithSubCategories(categories)
  }, [catgs, i18n.language])
  return (
    <CategoriesContext.Provider value={{ withSubCategories }}>
      {children}
    </CategoriesContext.Provider>
  )
}

const useCategories = () => {
  const context = useContext(CategoriesContext)

  if (!context) {
    throw new Error('useLocale must be used within an CategoriesProvider')
  }

  return context
}

export { CategoriesProvider, useCategories }
