import React from 'react'
import { setConfiguration } from 'react-grid-system'
import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'

import { gridWidths } from './media-queries'

const white = '#FFFFFF'
const black = '#000000'

// Set configuration for react grid system
// containder max widths
setConfiguration({
  ...gridWidths,
  maxScreenClass: 'lg',
})

export const colors = () => ({
  white,
  black,

  mainText: '#F4F4F4',
  titleText: '#F4F4F4',
  highlightText: '#129DFF',
  tokenText: '#D1D0D1',
  contentText: '#7D7D7D',
  bg: '#061121',
})

export const theme = () => ({
  ...colors(),
})

export const ThemeProvider = ({ children }) => (
  <StyledComponentsThemeProvider theme={theme}>
    {children}
  </StyledComponentsThemeProvider>
)

export const FixedGlobalStyle = createGlobalStyle`
  html, body, h1, h2, h3, p, span, input, textarea, button {
    font-family: "Inter";
    font-style: normal;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }
`

export const ThemeGlobalStyle = createGlobalStyle`
  html, main {
    color: ${({ theme }) => theme.secondaryDarkGrey};
    background-color: ${({ theme }) => theme.bg};
  }
`
