import _ from 'lodash'

const getCategoriesFilterByLanguages = (catgs, lang) => {
  const catgFiltered = _.filter(catgs, (catg) => {
    if (!_.isEmpty(catg)) {
      const exists = new RegExp(`-en$`).test(catg.node.slug)
      return exists
    }
  })
  return catgFiltered
}

const cleanSubCategories = (subCats) =>
  _.filter(subCats, (c) => !c.slug.startsWith('trending'))

export const getCategoryAndSubcategories = (catgs, lang) => {
  const array = getCategoriesFilterByLanguages(catgs, lang)
  const categories = []
  _.each(array, (category) => {
    if (!_.isEmpty(category.node.wpChildren.nodes)) {
      const obj = {
        ...category.node,
        url: category.node.description,
        wpChildren: _.map(
          cleanSubCategories(category.node.wpChildren.nodes),
          (subCat) => ({
            ...subCat,
            url: subCat.description,
          })
        ),
      }
      categories.push(obj)
    }
  })
  return { categories }
}

export const getPostSubCategories = (post) => {
  return _.head(
    _.filter(
      _.get(post, 'categories.nodes'),
      (c) =>
        !c.slug.startsWith('trending') &&
        !c.slug.startsWith('event') &&
        !c.slug.startsWith('product') &&
        !c.slug.startsWith('university') &&
        !c.slug.startsWith('company-news') &&
        !c.slug.startsWith('market-updates') &&
        !c.slug.startsWith('research') &&
        !c.slug.startsWith('nft-stories') &&
        !c.slug.startsWith('advanced') &&
        !c.slug.startsWith('beginner') &&
        !c.slug.startsWith('intermediate')
    )
  )
}
