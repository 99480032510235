export const screenSizes = {
  mobile: '375px',
  mobileBelow: '374px',
  tablet: '768px',
  tabletBelow: '767px',
  laptop: '1128px',
}

export const containerSizes = {
  mobile: '357px',
  tablet: '656px',
  laptop: '1080px',
}

export const mobileFirstDevice = {
  mobile: `(min-width: ${screenSizes.mobile})`,
  tablet: `(min-width: ${screenSizes.tablet})`,
  laptop: `(min-width: ${screenSizes.laptop})`,
  laptopL: `(min-width: ${screenSizes.laptop})`, // deprecated
}

export const deviceExchange = {
  mini: `(max-width: ${screenSizes.mobileBelow})`,
  mobile: `(min-width: ${screenSizes.mobile}) and (max-width: ${screenSizes.tabletBelow})`,
  tablet: `(min-width: ${screenSizes.tablet}) and (max-width: ${screenSizes.laptop})`,
  laptop: `(min-width: ${screenSizes.laptop})`,
}

const mobileContainer = parseInt(containerSizes.mobile.replace('px', ''), 10)
const tabletContainer = parseInt(containerSizes.tablet.replace('px', ''), 10)
const laptopContainer = parseInt(containerSizes.laptop.replace('px', ''), 10)
const mobileSize = parseInt(screenSizes.mobile.replace('px', ''), 10)
const tabletSize = parseInt(screenSizes.tablet.replace('px', ''), 10)
const laptopSize = parseInt(screenSizes.laptop.replace('px', ''), 10)

export const gridWidths = {
  breakpoints: [mobileSize, tabletSize, laptopSize, 1600, 1800],
  containerWidths: [
    mobileContainer,
    tabletContainer,
    laptopContainer,
    laptopContainer,
    laptopContainer,
  ],
}
