import { CLOSE_SEARCH, GET_QUERY, OPEN_SEARCH } from '../actions/types'

const defaultState = {
  isOpenSearch: false,
  query: '',
}

const LocaleReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_SEARCH: {
      return {
        ...state,
        isOpenSearch: true,
      }
    }
    case CLOSE_SEARCH: {
      return {
        ...state,
        isOpenSearch: false,
      }
    }
    case GET_QUERY: {
      return {
        ...state,
        query: action.payload,
      }
    }
    default:
      return state
  }
}

export default LocaleReducer
