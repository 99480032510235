exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-company-post-js": () => import("./../../../src/templates/company-post.js" /* webpackChunkName: "component---src-templates-company-post-js" */),
  "component---src-templates-event-post-js": () => import("./../../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-financial-statement-js": () => import("./../../../src/templates/financial-statement.js" /* webpackChunkName: "component---src-templates-financial-statement-js" */),
  "component---src-templates-market-js": () => import("./../../../src/templates/market.js" /* webpackChunkName: "component---src-templates-market-js" */),
  "component---src-templates-market-post-js": () => import("./../../../src/templates/market-post.js" /* webpackChunkName: "component---src-templates-market-post-js" */),
  "component---src-templates-nftstories-js": () => import("./../../../src/templates/nftstories.js" /* webpackChunkName: "component---src-templates-nftstories-js" */),
  "component---src-templates-nftstories-post-js": () => import("./../../../src/templates/nftstories-post.js" /* webpackChunkName: "component---src-templates-nftstories-post-js" */),
  "component---src-templates-product-post-js": () => import("./../../../src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-products-news-js": () => import("./../../../src/templates/products-news.js" /* webpackChunkName: "component---src-templates-products-news-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-research-post-js": () => import("./../../../src/templates/research-post.js" /* webpackChunkName: "component---src-templates-research-post-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-trending-js": () => import("./../../../src/templates/trending.js" /* webpackChunkName: "component---src-templates-trending-js" */),
  "component---src-templates-university-js": () => import("./../../../src/templates/university.js" /* webpackChunkName: "component---src-templates-university-js" */),
  "component---src-templates-university-post-js": () => import("./../../../src/templates/university-post.js" /* webpackChunkName: "component---src-templates-university-post-js" */)
}

