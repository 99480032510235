import { GET_POSTS } from '../actions/types'

const defaultState = {
  categories: [],
}

const PostsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_POSTS: {
      return {
        ...state,
        posts: action.payload,
      }
    }
    default:
      return state
  }
}

export default PostsReducer
